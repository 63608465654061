import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CountryService, validateOptions } from '@nbg-digital/shared/util';
import { AutoCompleteOption, NvModalComponent, PartialFormComponent } from '@nbg-digital/ui-dpl-components';
import { map, Observable } from 'rxjs';
import { AddressDataConfig } from './address-data-config';
import { AddressService, ValidateAddressData, validationFlag } from '@nbg-digital/application/domain';
import { CorrectedPostalAddress } from '@nbg-digital/application/domain';
import { first } from 'rxjs/operators';

export interface AddressData {
  city: string;
  country: string;
  street: string;
  housenumber: string;
  zip: string;
}

@Component({
  selector: 'nv-application-address-data',
  templateUrl: './address-data.component.html',
})
export class AddressDataComponent extends PartialFormComponent<AddressData> {
  @ViewChild('addressModal', { static: true })
  private addressModal: NvModalComponent;
  public correctedAddress: CorrectedPostalAddress;
  public suggestedAddresses: CorrectedPostalAddress[] = [];

  @Input() set config(config: AddressDataConfig) {
    this._config = config;
    this.applyConfig();
  }

  get config(): AddressDataConfig {
    return this._config;
  }

  private _config: AddressDataConfig;
  public flag: string;

  countries: Observable<AutoCompleteOption[]> = this.countryService
    .getCountryList()
    .pipe(map((countries) => countries.map((c) => ({ value: c.iso, text: c.country }))));

  constructor(
    private fBuilder: UntypedFormBuilder,
    private countryService: CountryService,
    private addressService: AddressService
  ) {
    super();

    this.formGroup = this.fBuilder.group(
      {
        street: '',
        housenumber: '',
        zip: '',
        city: '',
        country: '',
      },
      { updateOn: 'blur' }
    );

    // iban debounce, delay, ggf.
    this.formGroup.valueChanges.pipe().subscribe(() => {
      const controls = this.formGroup.controls;
      if (controls.street.value && controls.housenumber.value && controls.zip.value && controls.city.value) {
        this.validateAddress();
      }
    });
  }

  applyConfig() {
    // TODO: Hier Validatoren applyen
    const controls = this.formGroup.controls;
    controls.country.setValidators(Validators.required);

    controls.street.setValidators(this.config.streetRequired ? [Validators.required] : []);
    controls.housenumber.setValidators(this.config.housenumberRequired ? [Validators.required] : []);
    controls.zip.setValidators(this.config.zipRequired ? [Validators.required, Validators.pattern('^[0-9]{5}')] : []);
    controls.city.setValidators(
      this.config.cityRequired ? [Validators.required, Validators.pattern('[a-zA-ZüöäÜÖÄß/()\\- ]+')] : []
    );
    if (!this.config.countryEditable) {
      this.setReadOnlyCountryValue('DE');
    } else {
      controls.country.setValidators([Validators.minLength(2), Validators.required]);
      controls.country.setAsyncValidators(validateOptions(this.countries));
    }
  }

  // Wird onBlur vom letzten Feld ausgeführt
  validateAddress() {
    const controls = this.formGroup.controls;
    const validateAddressData: ValidateAddressData = {
      streetName: controls.street.value,
      zipCode: controls.zip.value,
      city: controls.city.value,
      houseNumber: controls.housenumber.value,
    };
    this.suggestedAddresses = [];
    console.log(validateAddressData);
    this.addressService
      .validateAddress(validateAddressData)
      .pipe(first())
      .subscribe((response) => {
        console.log(response);
        this.flag = response.validationDataPostalAddressList[0].flagADCheckResult;
        if (this.flag !== validationFlag.Valid) {
          if (this.flag === validationFlag.Suggestion) {
            response.validationDataPostalAddressList.forEach((element) => {
              this.suggestedAddresses.push(element.correctedPostalAddress);
            });
          }
          if (this.flag === validationFlag.Corrected) {
            this.correctedAddress = response.validationDataPostalAddressList[0].correctedPostalAddress;
            this.correctAddress(this.correctedAddress);
            // Nachricht anzeigen, dass Adresse übernommen wurde?
          } else {
            this.addressModal.open();
          }
        }
      });
  }

  deleteAddress() {
    const controls = this.formGroup.controls;
    controls.street.reset();
    controls.housenumber.reset();
    controls.zip.reset();
    controls.city.reset();
  }

  correctAddress(newAddress: CorrectedPostalAddress) {
    console.log('click, newAddress: ', newAddress);
    this.formGroup.patchValue({
      street: newAddress.streetName,
      housenumber: newAddress.houseNumber,
      zip: newAddress.zipCode,
      city: newAddress.city,
    });
    this.addressModal.close();
  }

  private setReadOnlyCountryValue(country: string) {
    this.formGroup.patchValue({ country });
  }

  protected toFormData(value: Partial<AddressData>) {
    if (value == null) {
      return '';
    }
    return {
      ...value,
    };
  }

  protected fromFormData(): AddressData {
    return {
      ...this.formGroup.getRawValue(),
      street: this.formGroup.value.street || undefined,
      housenumber: this.formGroup.value.housenumber || undefined,
      zip: this.formGroup.value.zip || undefined,
      city: this.formGroup.value.city || undefined,
    };
  }
}
