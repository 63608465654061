import { ReactiveFormsModule } from '@angular/forms';
import { AddressDataComponent } from './address-data.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  NvAutoCompleteModule,
  NvButtonModule,
  NvCardModule,
  NvErrorModule,
  NvFormInfoContainerModule,
  NvInfoTextContainerModule,
  NvInputModule,
  NvLoadingSpinnerModule,
  NvModalModule,
} from '@nbg-digital/ui-dpl-components';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [AddressDataComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NvCardModule,
    NvErrorModule,
    NvInputModule,
    NvModalModule,
    NvInfoTextContainerModule,
    NvFormInfoContainerModule,
    NvAutoCompleteModule,
    NvButtonModule,
    NvLoadingSpinnerModule,
  ],
  exports: [AddressDataComponent],
})
export class AddressDataModule {}
